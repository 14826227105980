import React from "react";
import styled from "styled-components";

import {
  colors,
  spacings,
  breakpoints,
} from "../UIGlobals";

const ContentWrapper = styled.div`
  min-height: ${props => (props.$autoHeight ? "0" : props.$minHeight ? props.$minHeight : "calc(100vh - 70px - 376px)")};
  background: ${props => (props.$background ? colors()[props.$background] : colors().dirtyWhite)};
  padding: ${props => (props.$padding ? props.$padding : "30px 0px")};
  max-width: 100%;
  overflow-x: ${props => (props.noOverflow ? "visible" : "hidden")};
  @media (${breakpoints().maxXL}) {
    padding: 30px 0px;
    min-height: ${props =>
  props.$autoHeight ? "0" : props.$minHeight ? props.$minHeight : "calc(100vh - 70px - 339px)"};
  }
  @media (${breakpoints().maxL}) {
    padding: 30px 0px;
    min-height: ${props =>
    props.$autoHeight ? "0" : props.$minHeight ? props.$minHeight : "calc(100vh - 70px - 299px)"};
  }
`;

const ContentInner = styled.div`
  min-width: ${props => (props.$minWidth ? props.$minWidth : `${spacings.mainContent.desktop.minWidth}px`)};
  width: ${props => (props.width ? props.width : `${spacings.mainContent.desktop.maxWidth}px`)};
  background-color: ${props => (props.$background ? colors()[props.$background] : "transparent")};
  max-width: ${(props) => props.$isFullWidthLesson ? "100%" : (props.$maxWidth || "80%")};
  margin: 0 auto;
  @media (${breakpoints().maxXL}) {
    width: 100%;
    min-width: 100%;
    padding: ${(props) => props.$isFullWidthLesson ? "0" : "0 25px"};
  }
  @media (${breakpoints().maxM}) {
    ${(props) => props.$isFullWidthLesson && "padding: 0 25px"}
  }
  @media (${breakpoints().maxM}) {
    ${(props) => props.noMobilePadding && "padding: 0;"}
  }
`;

const ReducePadding = styled.div`
  @media (${breakpoints().maxL}) {
    margin-left: -25px;
    margin-right: -25px;
  }
`;

const NegativeMarginTopMobile = styled.div`
  @media (${breakpoints().maxL}) {
    margin-top: -30px;
  }
`;

const ContentContainer = ({
  children,
  background,
  autoHeight,
  minHeight,
  padding,
  noMobilePadding,
  isFullWidthLesson,
  noOverflow,
}) => (
  <ContentWrapper
    $background={background}
    $autoHeight={autoHeight}
    $minHeight={minHeight}
    noOverflow={noOverflow}
    $padding={isFullWidthLesson ? "0" : padding}
  >
    <ContentInner
      minHeight={minHeight}
      noMobilePadding={noMobilePadding}
      $isFullWidthLesson={isFullWidthLesson}
      width={isFullWidthLesson ? "100%" : `${spacings.mainContent.desktop.maxWidth}px`}
    >
      {children}
    </ContentInner>
  </ContentWrapper>
);

export {
  ContentContainer,
  ContentWrapper,
  ContentInner,
  ReducePadding,
  NegativeMarginTopMobile,
};
