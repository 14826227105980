import React from "react";
import i18n from "i18next";
import styled, { css } from "styled-components";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import { colors, breakpoints } from "../../UIGlobals";
import { ContentContainer } from "../../UIElements";
import { AppConfig } from "../../appConfig";
import NetworkErrorModal from "../networkErrorModal";
import { OfflineHandler, DetectorHandler } from "../../helpers/connectionHandler";

const Footer = styled.footer`
  background: ${colors().semiDarkGray};
  width: 100%;
  color: ${colors().footerGray};
  display: block;

  @media (${breakpoints().maxM}) {
    padding-bottom: calc(70px + env(safe-area-inset-bottom)); 
  }
`;

const FooterNav = styled.nav`
  width: 100%;
  padding-bottom: 36px;
  border-bottom: 1px solid #666;
  @media (${breakpoints().maxM}) {
    display: flex;
    flex-direction: column;
    padding-bottom: 26px;
  }
`;

const footerNavItemStyles = css`
  display: inline-block;
  height: 15px;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${colors().white};
  text-decoration: none;
  margin-right: 50px;

  @media (${breakpoints().maxM}) {
    display: block;
    margin-bottom: 10px;
    padding: 10px 0;
    height: initial;
    margin-right: 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const FooterNavItem = styled(Link)`
  ${footerNavItemStyles}
`;

const FooterNavLink = styled.a`
  ${footerNavItemStyles}
`;

const FooterDisclaimer = styled.p`
  display: block;
  padding: 36px 0px 0px 0px;
  font-size: 1.4rem;
  line-height: 1.71;
  text-align: left;
  color: ${colors().footerGrey};
`;

class MainFooter extends React.Component {
  state = { offlineMode: false };

  setOfflineMode(event) {
    event.preventDefault();
    this.setState({ offlineMode: true });
  }

  render() {
    const { offlineMode } = this.state;

    return (
      <DetectorHandler render={() => (
        <Footer>
          {offlineMode && (
            <OfflineHandler>
              <NetworkErrorModal isOpen />
            </OfflineHandler>
          )}
          <ContentContainer background="semiDarkGrey" autoHeight>
            <FooterNav>
              <FooterNavItem to="/klauzula-informacyjna" target="_blank" rel="noopener noreferrer">
                {i18n.t("app.mainFooter.informationDuty")}
              </FooterNavItem>
              <FooterNavItem to="/polityka-prywatnosci" target="_blank" rel="noopener noreferrer">
                {i18n.t("app.mainFooter.privacyPolicy")}
              </FooterNavItem>
              <FooterNavItem to="/regulamin" target="_blank" rel="noopener noreferrer">
                {i18n.t("app.mainFooter.regulations")}
              </FooterNavItem>
              <FooterNavItem to="/regulamin-szkolen" target="_blank" rel="noopener noreferrer">
                {i18n.t("app.mainFooter.trainingRegulations")}
              </FooterNavItem>
              <FooterNavLink href={`mailto:${AppConfig.platformContactEmail}`}>
                {i18n.t("app.mainFooter.help")}
              </FooterNavLink>
            </FooterNav>
            <FooterDisclaimer>
              {i18n.t("app.mainFooter.footerDisclaimer_1", { year: dayjs().year() })}
            </FooterDisclaimer>
            <FooterDisclaimer>
              {i18n.t("app.mainFooter.footerDisclaimer_2")}
            </FooterDisclaimer>
          </ContentContainer>
        </Footer>
      )} />
    );
  }
}

export default MainFooter;
