import { all, put, takeLatest } from "redux-saga/effects";

import * as userRegistrationApi from "../services/api";
import { store } from "../../store";

import { UserRegistrationTypes, UserRegistrationActions } from "./userRegistration.reducer";

function* getData({ token }) {
  yield put(UserRegistrationActions.setLoading(true));
  const { data } = yield userRegistrationApi.getFormData(token);
  yield put(UserRegistrationActions.setData(data));
  yield put(UserRegistrationActions.setLoading(false));
}

function* sendData({ token }) {
  yield put(UserRegistrationActions.setLoading(true));
  yield put(UserRegistrationActions.setWasSubmitted(true));

  const { data, response } = yield userRegistrationApi.sendFormData(
    token,
    store.getState().userRegistration.data.sections,
  );

  if (data) {
    yield put(UserRegistrationActions.setData(data));
  } else if (response) {
    if (response.status === 409) {
      if (response.data.is_valid) {
        window.alert("Wystąpił nieoczekiwany błąd serwera");
      } else {
        response.data.sections.map((section, parentIndex) => (
          !section.is_valid && section.fields.forEach((field, fieldIndex) => {
            if (!field.is_valid) {
              UserRegistrationActions.setValidation(parentIndex, fieldIndex, false);
            }
          })
        ))
      }
    }
    yield put(UserRegistrationActions.setData(response.data));
  }

  yield put(UserRegistrationActions.setLoading(false));
}

function* setPassword({ token, pass, onError }) {
  yield put(UserRegistrationActions.setLoading(true));
  const { data } = yield userRegistrationApi.setPassword(token, pass);

  if (data.success) {
    yield put(UserRegistrationActions.setData(data));
  } else {
    onError(data.errors ? Object.values(data.errors)[0] : data.message);
  }

  yield put(UserRegistrationActions.setLoading(false));
}

function* remindPassword({ login }) {
  yield put(UserRegistrationActions.setLoading(true));
  const { data } = yield userRegistrationApi.remindPassword(login);

  if (data) {
    yield put(UserRegistrationActions.setData(data));
  }

  yield put(UserRegistrationActions.setLoading(false));
}

export function* watchUserRegistration() {
  yield all([
    yield takeLatest(UserRegistrationTypes.GET_DATA, getData),
    yield takeLatest(UserRegistrationTypes.SEND_DATA, sendData),
    yield takeLatest(UserRegistrationTypes.SET_PASSWORD, setPassword),
    yield takeLatest(UserRegistrationTypes.REMIND_PASSWORD, remindPassword),
  ]);
}
