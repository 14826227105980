import React from "react";

import { QuestionAnswer } from "../../../../quiz/UIElements";
import { QuestionOpenSimple } from "../questionOpenSimple/questionOpenSimple.component";

export const QuestionChoiceOpen = ({ section, questionId, active, multi, setAnswer, parentAnswer }) => {
  const question = section.questions.find((question) => question.id === questionId);

  const boundSetAnswer = ({ target }) => {
    setAnswer(question.answers[0].id, target.value);
    setAnswer(parentAnswer, target.value.length > 0);
  };

  return (
    <QuestionAnswer multi={multi} selected={active}>
      <QuestionOpenSimple active={true} question={question} setAnswer={boundSetAnswer} />
    </QuestionAnswer>
  );
};
