import React from "react";

import { colors } from "../UIGlobals";

class ProgressRing extends React.Component {
  constructor(props) {
    super(props);

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  render() {
    const { radius } = this.props;

    return (
      <svg height={radius * 2} width={radius * 2} viewBox="0 0 351.065979 260.6532898">
        <g>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            x1="0"
            y1="130.3266449"
            x2="351.065979"
            y2="130.3266449"
          >
            <stop offset="0" style={{ strokeDashoffset: "stop-color:#EB99AD" }} />
            <stop offset="1" style={{ strokeDashoffset: `stop-color:${colors().red}`}} />
          </linearGradient>
          <path
            fill=""
            d="M22.0391426,260.6532898c-10.9481325-19.7782288-17.9117317-41.2016296-20.6964302-63.677063
    l17.8631668-2.2125244c2.5005665,20.1807404,8.7525368,39.4159241,18.5819283,57.1714172L22.0391426,260.6532898z
     M0.6961435,190.8926697c-2.0058224-22.5940247,0.3279216-45.0052185,6.9366508-66.6115341l17.2124481,5.2652206
    c-5.9268131,19.3773193-8.0198841,39.4822693-6.2194576,59.7548676L0.6961435,190.8926697z M9.5256634,118.4559555
    c3.9868956-11.5670395,9.2331181-22.7952271,15.5916939-33.3718185
    c5.237669-8.7129059,11.2400227-16.9957504,17.8405628-24.6199074l13.6082001,11.7821617
    c-5.9252815,6.8439331-11.3157959,14.2833633-16.0216484,22.1115875
    c-5.7125473,9.5019226-10.4232159,19.5833206-14.0011387,29.9639511L9.5256634,118.4559555z M313.4380188,251.5877686
    c9.8523865-17.828186,16.0741882-37.0583801,18.4934998-57.1563721l17.8715515,2.1520081
    c-2.6986694,22.4088745-9.6320801,43.8444977-20.6098938,63.7097168L313.4380188,251.5877686z M60.2355804,68.1551895
    L47.048481,55.9037666c15.386776-16.5613556,33.6077156-29.8186569,54.1575584-39.4033661l7.6084824,16.3133125
    C90.3787613,41.4120636,74.0344772,53.3027153,60.2355804,68.1551895z M332.4931946,188.9801788
    c1.7181702-20.2015686-0.4399719-40.3103333-6.4154053-59.7667084l17.20755-5.2842941
    c6.6540527,21.6691666,9.0576172,44.0687485,7.1434326,66.5771866L332.4931946,188.9801788z M113.8408585,30.5756264
    l-7.0358276-16.5684052c20.8352814-8.847394,42.86483-13.5563002,65.4762955-13.9951735l0.3491974,17.9964294
    C152.3292999,18.4027233,132.5497437,22.6308384,113.8408585,30.5756264z M324.3708496,123.9840164
    c-6.6491394-19.245491-16.7726746-36.7448196-30.0888672-52.0113068L307.8464661,60.14011
    c14.8453369,17.0197716,26.1289978,36.5222816,33.5371704,57.9662285L324.3708496,123.9840164z M178.1181641,17.9978294
    L178.3959198,0c22.642807,0.3496451,44.6875305,5.0102887,65.5217285,13.8530455l-7.0322418,16.5695229
    C218.1990204,22.4915237,198.427002,18.3111877,178.1181641,17.9978294z M290.5986633,67.896698
    c-9.9293213-10.6063919-21.3419189-19.8181725-33.9208069-27.379837c-0.0033569-0.0020103-0.0075378-0.004528-0.0108948-0.0065384
    c-4.7749329-2.8704033-9.7409058-5.5121078-14.7594757-7.8521385l7.6057434-16.3142262
    c5.5873413,2.6050434,11.1144562,5.54533,16.4267426,8.7387466c0.0041809,0.0025158,0.0083618,0.0050316,0.0125427,0.0075474
    c14.0050964,8.4190102,26.7190552,18.6825638,37.7863159,30.5045509L290.5986633,67.896698z"
          />
        </g>
        <g>
          <path
            d="M22.0391426,260.6532898c-10.9481325-19.7782288-17.9117317-41.2016296-20.6964302-63.677063
    l17.8631668-2.2125244c2.5005665,20.1807404,8.7525368,39.4159241,18.5819283,57.1714172L22.0391426,260.6532898z
     M0.6961435,190.8926697c-2.0058224-22.5940247,0.3279216-45.0052185,6.9366508-66.6115341l17.2124481,5.2652206
    c-5.9268131,19.3773193-8.0198841,39.4822693-6.2194576,59.7548676L0.6961435,190.8926697z M9.5256634,118.4559555
    c3.9868956-11.5670395,9.2331181-22.7952271,15.5916939-33.3718185
    c5.237669-8.7129059,11.2400227-16.9957504,17.8405628-24.6199074l13.6082001,11.7821617
    c-5.9252815,6.8439331-11.3157959,14.2833633-16.0216484,22.1115875
    c-5.7125473,9.5019226-10.4232159,19.5833206-14.0011387,29.9639511L9.5256634,118.4559555z M313.4380188,251.5877686
    c9.8523865-17.828186,16.0741882-37.0583801,18.4934998-57.1563721l17.8715515,2.1520081
    c-2.6986694,22.4088745-9.6320801,43.8444977-20.6098938,63.7097168L313.4380188,251.5877686z M60.2355804,68.1551895
    L47.048481,55.9037666c15.386776-16.5613556,33.6077156-29.8186569,54.1575584-39.4033661l7.6084824,16.3133125
    C90.3787613,41.4120636,74.0344772,53.3027153,60.2355804,68.1551895z M332.4931946,188.9801788
    c1.7181702-20.2015686-0.4399719-40.3103333-6.4154053-59.7667084l17.20755-5.2842941
    c6.6540527,21.6691666,9.0576172,44.0687485,7.1434326,66.5771866L332.4931946,188.9801788z M113.8408585,30.5756264
    l-7.0358276-16.5684052c20.8352814-8.847394,42.86483-13.5563002,65.4762955-13.9951735l0.3491974,17.9964294
    C152.3292999,18.4027233,132.5497437,22.6308384,113.8408585,30.5756264z M324.3708496,123.9840164
    c-6.6491394-19.245491-16.7726746-36.7448196-30.0888672-52.0113068L307.8464661,60.14011
    c14.8453369,17.0197716,26.1289978,36.5222816,33.5371704,57.9662285L324.3708496,123.9840164z M178.1181641,17.9978294
    L178.3959198,0c22.642807,0.3496451,44.6875305,5.0102887,65.5217285,13.8530455l-7.0322418,16.5695229
    C218.1990204,22.4915237,198.427002,18.3111877,178.1181641,17.9978294z M290.5986633,67.896698
    c-9.9293213-10.6063919-21.3419189-19.8181725-33.9208069-27.379837c-0.0033569-0.0020103-0.0075378-0.004528-0.0108948-0.0065384
    c-4.7749329-2.8704033-9.7409058-5.5121078-14.7594757-7.8521385l7.6057434-16.3142262
    c5.5873413,2.6050434,11.1144562,5.54533,16.4267426,8.7387466c0.0041809,0.0025158,0.0083618,0.0050316,0.0125427,0.0075474
    c14.0050964,8.4190102,26.7190552,18.6825638,37.7863159,30.5045509L290.5986633,67.896698z"
          />
        </g>
      </svg>
    );
  }
}

class ProgressDashed extends React.Component {
  constructor(props) {
    super(props);

    this.state = { progress: 0 };
  }

  componentDidMount() {
    const interval = setInterval(() => {
      this.setState({ progress: this.state.progress + 1 });
      if (this.state.progress === 100) clearInterval(interval);
    }, 10);
  }

  render() {
    return <ProgressRing radius={78} stroke={3} progress={this.state.progress} />;
  }
}

export { ProgressDashed };
