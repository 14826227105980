import React from "react";

import { QuestionAnswer } from "../../../../quiz/UIElements";

export const QuestionChoiceAnswer = ({ value, action, active, preview, correct, children, multi, error }) => (
  <QuestionAnswer
    onClick={!preview ? action : () => {}}
    selected={active}
    correct={correct}
    preview={preview}
    multi={multi}
    error={error}
  >
    {value}
    {children}
  </QuestionAnswer>
);
