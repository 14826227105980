import React, { Component } from "react";
import styled from "styled-components";

import {
  colors,
  breakpoints,
  transition,
} from "../UIGlobals";
import { Icon } from "./";

const ThumbnailListWrapper = styled.div`
  position: relative;
`;

const ThumbnailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  transform: translateX(-${props => props.offset}px);
  transition: 0.2s all ease-in-out;
  scroll-snap-type: mandatory;
  padding-top: 20px;
  @media (${breakpoints().maxL}) {
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    transform: none;
    -webkit-overflow-scrolling: touch;
  }
`;

const ThumbnailCrop = styled.div`
  @media (${breakpoints().minL}) {
    overflow: hidden;
  }
`;

const Arrow = styled.button`
  width: 50px;
  height: 50px;
  background: ${colors().white};
  border: none;
  border-radius: 50px;
  outline: none;
  box-shadow: 0 2px 10px 0 rgba(31, 27, 22, 0.02), 0 0 20px 0 rgba(31, 27, 22, 0.03);
  bottom: calc(50% - 25px);
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  z-index: 10;
  transition: ${transition().custom};
  &:hover {
    background: ${colors().almostWhite};
    cursor: pointer;
  }
  box-shadow: 1px 1.732px 0px 0px rgba(31, 27, 22, 0.16), 0px 2px 10px 0px rgba(31, 27, 22, 0.04),
    0px 0px 20px 0px rgba(31, 27, 22, 0.06);
  @media (${breakpoints().maxL}) {
    display: none;
  }
`;

const ArrowLeft = styled(Arrow)`
  left: 0%;
  transform: ${props => (props.transform ? props.transform : "translate3d(-50%, -50%, 0)")};
`;

const ArrowRight = styled(Arrow)`
  right: 0%;
  transform: ${props => (props.transform ? props.transform : "translate3d(-50%, -50%, 0)")};
`;

class ThumbnailSlider extends Component {
  constructor() {
    super();
    this.state = { offset: 0, isScrollLeftPossible: false, isScrollRightPossible: true };
    this.wrapper = React.createRef();
    this.row = React.createRef();
  }

  componentDidMount() {
    this.toggleArrows();
  }

  scrollRight = () => {
    if (this.state.offset < this.row.current.scrollWidth - this.wrapper.current.offsetWidth) {
      this.setState({ offset: this.state.offset + this.wrapper.current.offsetWidth + 20 }, () => {
        this.toggleArrows();
      });
    }
  }

  scrollLeft = () => {
    if (this.state.offset > this.wrapper.current.offsetWidth) {
      this.setState({ offset: this.state.offset - this.wrapper.current.offsetWidth - 20 }, () => {
        this.toggleArrows();
      });
    } else {
      this.setState({ offset: 0 }, () => {
        this.toggleArrows();
      });
    }
  }

  toggleArrows = () => {
    this.setState({
      isScrollLeftPossible: this.state.offset > 0,
      isScrollRightPossible:
        this.row.current.scrollWidth > this.wrapper.current.offsetWidth &&
        this.state.offset < this.row.current.scrollWidth - this.wrapper.current.offsetWidth
    });
  };

  render() {
    const children = React.Children.toArray(this.props.children);
    return (
      <ThumbnailListWrapper ref={this.wrapper}>
        {this.state.offset > 0 && (
          <ArrowLeft transform="translate3d(-50%, -50%, 0)" onClick={() => this.scrollLeft()}>
            <Icon icon="arrow_left" size="14" color={colors().black} />
          </ArrowLeft>
        )}
        <ThumbnailCrop>
          <ThumbnailRow ref={this.row} offset={this.state.offset}>
            {children.map(child => child)}
          </ThumbnailRow>
        </ThumbnailCrop>
        {this.state.isScrollRightPossible && (
          <ArrowRight transform="translate3d(50%, -50%, 0)" onClick={() => this.scrollRight()}>
            <Icon icon="arrow_left" size="14" color={colors().black} rotation="-180deg" />
          </ArrowRight>
        )}
      </ThumbnailListWrapper>
    );
  }
}

export { ThumbnailSlider };
