import styled from "styled-components";

import { Color } from "../../theme";
import { breakpoints, transition } from "../../UIGlobals";

export const Container = styled.div`
  background: ${Color.almostWhite};
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (${breakpoints().maxL}) {
    position: relative;
    width: 100%;
    height: auto;
    padding: 30px 25px;
    min-height: 100vh;
  }
`;

export const ButtonsContainer = styled.div`
  width: 873px;
  height: auto;
  display: flex;
  flex-flow: wrap;

  @media (${breakpoints().maxL}) {
    width: 100%;
  }
`;

export const BrandButton = styled.button`
  width: 290px;
  height: 172px;
  border: none;
  background: ${Color.white};
  border-radius: 0px;
  margin-right: 1px;
  margin-bottom: 1px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${transition().custom};
  &:hover {
    opacity: 0.7;
    cursor: pointer;
    background-color: ${Color.whiteHover};
  }
  @media (${breakpoints().maxL}) {
    width: calc(50% - 1px);
    height: 104px;
  }
`;

export const BrandImage = styled.img`
  max-width: 110px;
  max-height: 65px;
  height: auto;
  display: block;

  @media (${breakpoints().maxL}) {
    max-width: 73px;
    max-height: 43px;
  }
`;

export const SelectHeader = styled.h4`
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.38;
  text-align: center;
  color: ${Color.gray4};
  margin-bottom: 30px;
  display: block;
`;
