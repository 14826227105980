import React from "react";
import { useTranslation } from "react-i18next";

import { QuizPager, QuizPagerMarker } from "../../../../quiz/UIElements";

export const QuizToc = ({ current, length }) => {
  const { t } = useTranslation();

  return (
    <QuizPager>
      <QuizPagerMarker>{t("global.question")} {current}</QuizPagerMarker> | {length}
    </QuizPager>
  );
};
