import styled from "styled-components";

import { colors } from "../UIGlobals";

const HeaderNav = styled.div`
  color: ${colors().gray1};
  display: flex;
  align-items: center;
  font-size: 1.5rem;
`;

const ArrowLeft = styled.a`
  display: flex;
  cursor: pointer;
  padding: 10px;
  margin-left: -10px;
  margin-right: 30px;
`;

const ArrowRight = styled.a`
  display: flex;
  cursor: pointer;
  padding: 10px;
  margin-left: auto;
  margin-right: -10px;
`;

const Numbers = styled.span`
  font-weight: 500;
`;

const Name = styled.span`
  font-weight: 600;
  padding-left: 15px;
  color: ${colors().gray4};
  align-content: center;
`;

export {
  HeaderNav,
  ArrowRight,
  ArrowLeft,
  Numbers,
  Name,
};
