import styled from "styled-components";

import { spacings } from "../../../../UIGlobals";
import { Color } from "../../../../theme/colors";
import { Media } from "../../../../theme/media";

import { PageHeader } from "../../../../theme/typography";

export const Container = styled.div`
  background-color: ${Color.almostWhite};
  padding: 80px 25px;
`;

export const ContentWrapper = styled.div`
  max-width: ${spacings.mainContent.desktop.maxWidth}px;
  margin: 0 auto;
`;

export const Title = styled(PageHeader)`
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.11;
  margin-bottom: 25px;
  text-transform: uppercase;
`;

export const ContactsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemWrapper = styled.div`
  margin: 0 0 20px;

  ${Media.desktop`
    margin: 0 10px;
    flex: 1;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  `}
`;
