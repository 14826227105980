import React from "react";
import styled from "styled-components";
import i18n from "i18next";

import { Icon } from "../../UIElements";
import { colors, breakpoints } from "../../UIGlobals";
import { DetectorHandler } from "../../helpers/connectionHandler";

const Toast = styled.div`
  position: fixed;
  bottom: 30px;
  left: 110px;
  z-index: 999;
  background-color: ${props => props.$isOnline ? colors().lightGreen : colors().black};
  color: ${props => props.$isOnline ? colors().green : colors().white};
  width: 160px;
  height: 90px;
  border-radius: 3px;
  border: 1px solid ${props => props.$isOnline ? colors().green2 : colors().black};
  display: flex;
  flex-direction: column;
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => props.$isVisible ? "1" : "0"};
  pointer-events: none;
  @media (${breakpoints().maxL}) {
    left: 30px;
  }
  @media (${breakpoints().maxM}) {
    bottom: 80px;
  }
  @media (${breakpoints().maxS}) {
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 42px;
  }
`;

const ToastText = styled.p`
  flex: 1;
  padding: 9px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
`;

const IconWrapper = styled.div`
  flex: 1;
  padding: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${props => props.$isOnline ? colors().green2 : colors().gray6};
  @media (${breakpoints().maxS}) {
    display: none;
  }
`;

class OfflineToast extends React.Component {
  state = { isVisible: false };
  timeoutId = null;

  toggleMode() {
    clearTimeout(this.timeoutId);
    this.setState({ isVisible: true });
    this.timeoutId = setTimeout(() => this.setState({ isVisible: false }), 5000);
  }

  render() {
    const { isVisible } = this.state;

    return (
      <DetectorHandler onChange={() => this.toggleMode()} render={({ online }) => (
        <Toast $isVisible={isVisible} $isOnline={online}>
          <IconWrapper $isOnline={online}>
            <Icon
              icon={online ? "toast_online" : "toast_offline"}
              color={online ? colors().green2 : colors().white}
              size={22}
            />
          </IconWrapper>
          <ToastText>
            {online ? (
              i18n.t("app.offlineToast.youAreOnline")
            ) : (
              i18n.t("app.offlineToast.youAreOffline")
            )}
          </ToastText>
        </Toast>
      )} />
    );
  }
}

export default OfflineToast;
