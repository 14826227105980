import React, { Component } from "react";
import i18n from "i18next";

import Question from "./question";
import {
  QuizPager,
  QuizPagerMarker,
  QuizWrapper,
} from "./UIElements";

class QuizToc extends Component {
  render() {
    const { nav, questions } = this.props;
    const currentQuestionIndex = questions.findIndex(question => question.id === nav.currentChild) + 1;

    return (
      <QuizPager>
        <QuizPagerMarker>{ i18n.t("global.question") } {currentQuestionIndex}</QuizPagerMarker> | {questions.length}
      </QuizPager>
    );
  }
}

class Quiz extends Component {
  render() {
    const { questions, nav, markAnswerSelected, children } = this.props;
    const questionIndex = questions.findIndex(question => question.id === nav.currentChild);
    const currentQuestionIndex = questionIndex >= 0 ? questionIndex : 0;
    const currentQuestion = questions[currentQuestionIndex];
    const prevQuestion = currentQuestionIndex > 0 ? questions[currentQuestionIndex - 1] : false;

    return (
      <QuizWrapper>
        {children}
        <QuizToc nav={nav} questions={questions} action={this.props.setCurrentQuestion} />

        <Question
          question={currentQuestion}
          navPrev={() => {
            this.props.setCurrentQuestion(prevQuestion.id);
          }}
          isFirst={currentQuestionIndex === 0}
          callComplete={() => {
            currentQuestion.children.filter(e => e.selected === true).length > 0 &&
              this.props.markQuestionCompleted(currentQuestion.id);
          }}
          markAnswerSelected={markAnswerSelected}
        />
      </QuizWrapper>
    );
  }
}

export default Quiz;
