import React, { useState } from "react";
import i18n from "i18next";
import styled from "styled-components";

import { ClearfixContainer, Icon } from "../UIElements";
import { Button as ButtonBase } from "../components/button/button.component";
import { colors } from "../UIGlobals";
import { Color, FontWeight } from "../theme";
import { ButtonVariant } from "../components/button/button.constants";

import { QuestionType } from "./question.constants";
import { QuestionAnswer, QuestionButtons } from "./UIElements";

const QuestionTitle = styled.h1`
  font-size: 20px;
  line-height: 25px;
  font-weight: ${FontWeight.Bold};
`;

const QuestionSubtitle = styled.p`
  font-size: 14px;
  margin-top: 10px;
  color: ${Color.gray9};
`;

const WarnText = styled.p`
  position: absolute;
  top: -25px;
  color: ${Color.error};
  font-size: 12px;
  font-weight: ${FontWeight.SemiBold};
`;

const Button = styled(ButtonBase)`
  &:disabled {
    background-color: ${Color.almostWhite};
    color: ${Color.gray1};
  }

  & + & {
    margin-left: 20px;
  }
`;

const Header = styled.div`
  margin: 50px 0;
`;

const QuestionTitleWrapper = ({ value }) => <QuestionTitle dangerouslySetInnerHTML={{ __html: value }} />;

const QuestionAnswerWrapper = ({ value, action, active, preview, correct, children, multi }) => (
  <QuestionAnswer
    onClick={!preview ? action : () => {}}
    selected={active}
    correct={correct}
    preview={preview}
    multi={multi}
  >
    {value}
    {children}
  </QuestionAnswer>
);

const QuestionConfirm = ({ action, disabled }) => (
  <Button onClick={action} disabled={disabled}>
    { i18n.t("global.next") }
  </Button>
);

const QuestionBack = ({ action }) => (
  <Button onClick={action} variant={ButtonVariant.Outlined}>
    { i18n.t("global.previous") }
  </Button>
);

const Question = ({ question, callComplete, markAnswerSelected, navPrev, isFirst, preview }) => {
  const answers = question?.children.filter(question => question.type === "answer");
  const nothingSelected = !answers.some((answer) => answer.selected);
  const [showValidationMessage, setShowValidationMessage] = useState(false);

  const onNextClick = () => {
    if (nothingSelected) {
      setShowValidationMessage(true);
    } else {
      setShowValidationMessage(false);
      callComplete();
    }
  };

  return (
    <ClearfixContainer>
      <Header>
        {!!question && question.children
          .filter(question => question.type === "body")
          .map(question => <QuestionTitleWrapper {...question} key={question.value} />)}
        {question.params?.type === QuestionType.MultipleChoice && (
          <QuestionSubtitle>{i18n.t("quiz.question.multipleAnswersInfo")}</QuestionSubtitle>
        )}
      </Header>
      {answers.map((answer, index) => {
        return (
          <QuestionAnswerWrapper
            {...answer}
            key={index}
            multi={question.params && question.params.type === "multiple_choice"}
            action={() => markAnswerSelected(answer.parentId, answer.id)}
            active={answer.params.selected || answer.selected}
            preview={preview}
            correct={answer.params.correct}
          >
            {preview && answer.params.correct ? (
              <Icon icon="status_checkbox" size="13" color={colors().green} />
            ) : (
              preview && answer.params.selected && <Icon icon="close_fat" size="14" color={colors().red} />
            )}
          </QuestionAnswerWrapper>
        );
      })}
      {!preview && (
        <QuestionButtons isSingle={isFirst}>
          {showValidationMessage && nothingSelected && (
            <WarnText>
              {i18n.t("quiz.question.noAnswersMessage")}.
            </WarnText>
          )}
          {!isFirst && <QuestionBack action={navPrev} />}
          <QuestionConfirm action={onNextClick} disabled={nothingSelected} />
        </QuestionButtons>
      )}
    </ClearfixContainer>
  );
};

export default Question;
