import styled from "styled-components";

import { spacings } from "../../../../UIGlobals";
import { Media } from "../../../../theme/media";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px 25px 45px;

  ${Media.tablet`
    padding: 100px 25px;
  `}
`;

export const ContentWrapper = styled.div`
  width: ${spacings.mainContent.desktop.maxWidth}px;
`;

export const VideosWrapper = styled.div``;
