import { connect } from "react-redux";

import { Quiz, Summary } from "../quiz";

import { LessonActions } from "./redux/lesson.reducer";

const mapStateToProps = (state, ownProps) => ({ nav: state.lesson.nav, ...ownProps });

const mapDispatchToProps = dispatch => ({
  markQuestionCompleted: id => dispatch(LessonActions.setQuestionCompleted(id)),
  setCurrentQuestion: id => dispatch(LessonActions.setCurrentQuestion(id)),
  markAnswerSelected: (questionId, answerId) => dispatch(LessonActions.setAnswerSelected(questionId, answerId)),
});

const WrappedQuiz = connect(mapStateToProps, mapDispatchToProps)(Quiz);

export { WrappedQuiz, Summary };
