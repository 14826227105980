import styled from "styled-components";

import { colors } from "../../UIGlobals";

export const Wrapper = styled.div`
  height: 44px;
  width: 44px;
  border: 1px solid ${colors().gray5};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
