import styled from "styled-components";
import { colors } from "../../UIGlobals";

const QuizPager = styled.div`
  font-size: 1.2rem;
  line-height: 1.67;
  letter-spacing: 0.6px;
  text-align: left;
  color: #c4c4c4;
`;

const QuizPagerMarker = styled.strong`
  font-weight: 800;
  color: #000;
`;

const QuizWrapper = styled.div`
  padding: 30px 0px;
`;

const QuizStatus = styled.h4`
  font-size: 1.6rem;
  font-weight: 600;
  text-align: left;
  color: #d01340;
  margin: 50px 0px 50px 0px;
`;

const QuizSummary = styled.h1`
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: ${colors().black};
  margin: ${props => (props.offsetTop ? "30px" : "0px")} 0px 10px 0px;
`;

export {
  QuizPager,
  QuizPagerMarker,
  QuizWrapper,
  QuizStatus,
  QuizSummary,
};
