import React from "react";

import { Wrapper } from "./fullLogo.styled";
import { Icon } from "../index";

export const FullLogo = ({ cursorPointer }) => (
  <Wrapper>
    <Icon
      icon="platform_owner_logo_full_sidebar"
      size={227}
      cursor={cursorPointer && "pointer"}
    />
  </Wrapper>
);
