import { createActions, createReducer } from "reduxsauce";

import { deepClone } from "../../helpers";

export const { Types: UserRegistrationTypes, Creators: UserRegistrationActions } = createActions(
  {
    setLoading: ["loading"],
    getData: ["token"],
    setData: ["data"],
    sendData: ["token"],
    setValidation: ["parent", "field", "value"],
    setValue: ["parent", "field", "value"],
    setSubmitted: null,
    setWasSubmitted: ["wasSubmitted"],
    setPassword: ["token", "pass", "onError"],
    remindPassword: ["login"],
  },
  { prefix: "USER_REGISTRATION/" }
);

const INITIAL_STATE = {
  loading: false,
  data: [],
  wasSubmitted: false,
};

const setLoading = (state, { loading }) => ({ ...state, loading });

const setData = (state, { data }) => ({ ...state, data });

const setValidation = (state, { parent, field, value }) => {
  const data = deepClone(state.data);
  const fieldToEdit = data.sections.find(
    (section) => section.name === parent
  ).fields.find(
    (sectionField) => sectionField.name === field
  );
  fieldToEdit.is_valid = value;
  fieldToEdit.touched = true;

  return {
    ...state,
    data,
  };
};

const setValue = (state, { parent, field, value }) => {
  const data = deepClone(state.data);
  const fieldToEdit = data.sections.find(
    (section) => section.name === parent
  ).fields.find(
    (sectionField) => sectionField.name === field
  );
  fieldToEdit.value = value;

  if (parent === "agreements") {
    fieldToEdit.is_valid = value;
  }
  return {
    ...state,
    data,
  };
};

const setSubmitted = (state) => {
  const data = deepClone(state.data);
  const login = data.sections.find(
    (section) => section.name === "user"
  ).fields.find(
    (login) => login.name === "login"
  );

  if (login) {
    return {
      ...state,
      wasSubmitted: true,
      login: login.value
    };
  }

  return {
    ...state,
    wasSubmitted: true,
  }
};

const setWasSubmitted = (state, { wasSubmitted }) => ({ ...state, wasSubmitted });

export const reducer = createReducer(INITIAL_STATE, {
  [UserRegistrationTypes.SET_LOADING]: setLoading,
  [UserRegistrationTypes.SET_DATA]: setData,
  [UserRegistrationTypes.SET_VALIDATION]: setValidation,
  [UserRegistrationTypes.SET_VALUE]: setValue,
  [UserRegistrationTypes.SET_SUBMITTED]: setSubmitted,
  [UserRegistrationTypes.SET_WAS_SUBMITTED]: setWasSubmitted,
});
