import React, { useEffect } from "react";
import i18n from "i18next";
import styled from "styled-components";

import { ClearfixContainer } from "../UIElements";
import { Button as ButtonBase } from "../components/button/button.component";
import { Color } from "../theme";

import {
  QuestionButtons,
  QuizStatus,
  QuizSummary,
} from "./UIElements";
import Question from "./question";
import { SummaryWrapper } from "./summary.styled";

const Button = styled(ButtonBase)`
  &:disabled {
    background-color: ${Color.almostWhite};
    color: ${Color.gray1};
  }

  & + & {
    margin-left: 20px;
  }
`;

const QuestionConfirm = ({ action, label }) => (
  <Button onClick={action}>
    {label}
  </Button>
);

const Summary = ({ quiz, actions = false }) => {
  const questions = quiz ? (quiz.children.questions ? quiz.children.questions : quiz.children) : [];
  const passed = quiz.passed || quiz.params.find((param) => param.name === "passed")?.value;
  const result = quiz.result || quiz.params.find((param) => param.name === "result")?.value;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ClearfixContainer>
      {passed === true && <QuizStatus>{ i18n.t("form.form.congratulationsTestPassed") }</QuizStatus>}
      {passed === false && <QuizStatus>{ i18n.t("form.form.testNotPassed") }</QuizStatus>}
      {result !== null && <QuizSummary>{ i18n.t("form.form.answeredCorrectlyOn") } {(result * 100).toFixed()}% { i18n.t("form.form.questions") }</QuizSummary>}
      {result === null && <QuizSummary>{ i18n.t("forms.formAndTest.results") }:</QuizSummary>}
      {questions.map(question => <Question question={question} preview={true} key={question.children[0].value}/>)}
      {actions && (
        <QuestionButtons>
          {actions.map((action, index) => <QuestionConfirm action={action.action} label={action.label} key={index}/>)}
        </QuestionButtons>
      )}
    </ClearfixContainer>
  );
};

const SummaryShort = ({ actions = false, wrapped }) => {
  return (
    <SummaryWrapper wrapped={wrapped}>
      <ClearfixContainer>
        <QuizSummary offsetTop>{ i18n.t("form.form.thankYou") }!</QuizSummary>
        {actions && (
          <QuestionButtons>
            {actions.map((action, index) => <QuestionConfirm action={action.action} label={action.label} key={index}/>)}
          </QuestionButtons>
        )}
      </ClearfixContainer>
    </SummaryWrapper>
  );
};

export {
  Summary,
  SummaryShort,
};