import React from "react";
import styled from "styled-components";

import { Icon } from "./";
import { colors, breakpoints, transition } from "../UIGlobals";

const Badges = new Map();
Badges.set("gold", {
  icon: "status_medal",
  defaultColor: "#f6af45",
  background: "#fbf5da",
  border: "rgba(172, 129, 86, 0.15)"
});
Badges.set("silver", {
  icon: "status_medal",
  defaultColor: colors().silver,
  background: "#f4f5f9",
  border: "rgba(172, 129, 86, 0.15)"
});
Badges.set("bronze", {
  icon: "status_medal",
  defaultColor: colors().brown,
  background: "#f3ece6",
  border: "rgba(172, 129, 86, 0.15)"
});
Badges.set("completed", {
  icon: "status_checkbox",
  defaultColor: colors().green,
  background: "#ddeddd",
  border: "rgba(134, 169, 78, 0.15)",
  icoSize: 0.35
});
Badges.set("download", {
  icon: "status_download",
  defaultColor: colors().yellow,
  background: colors().white,
  border: " rgba(226, 158, 57, 0.15)",
  icoSize: 0.45
});
Badges.set("test_fail", {
  icon: "test_fail",
  defaultColor: colors().red,
  border: 0,
  background: colors().lightPink,
  icoSize: 0.45
});
Badges.set("test_ok", {
  icon: "test_ok",
  defaultColor: colors().green,
  border: 0,
  background: colors().veryLightGreen,
  icoSize: 0.45
});
Badges.set("filters", {
  icon: "filters",
  defaultColor: colors().black,
  border: 0,
  background: colors().white,
  icoSize: 0.45
});
Badges.set("filters_brand", {
  icon: "filters",
  border: colors().dirtyWhite,
  background: colors().white,
  icoSize: 0.35,
});
Badges.set("training_tab_1", {
  icon: "training_tab_1",
  color: colors().brandBasic,
  border: 0,
  background: colors().white,
  icoSize: 0.27
});
Badges.set("training_tab_2", {
  icon: "training_tab_2",
  color: colors().brandBasic,
  border: 0,
  background: colors().white,
  icoSize: 0.3
});
Badges.set("lock", {
  icon: "lock",
  defaultColor: colors().gray4,
  border: "rgba(172, 129, 86, 0.15)",
  background: "transparent",
  icoSize: 0.35
});
Badges.set("eye", {
  icon: "eye",
  defaultColor: colors().gray4,
  border: "rgba(172, 129, 86, 0.15)",
  background: colors().almostWhite,
  icoSize: 0.35
});
Badges.set("required", {
  icon: "required",
  defaultColor: colors().black,
  border: "rgba(172, 129, 86, 0.15)",
  background: colors().white,
  icoSize: 0.45
});

const BadgeWrapper = styled.div`
  width: ${props => props.size || 32}px;
  height: ${props => props.size || 32}px;
  border-radius: ${props => props.size || 32}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  line-height: ${props => props.size - 3 || 31}px;
  background: ${props => (props.$background ? props.$background : "transparent")};
  border: 1px solid ${props => (props.border && !props.hideBorder ? props.border : "transparent")};
  margin-right: ${props => (props.marginRight ? props.marginRight + "px" : "10px")};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom + "px" : "10px")};
  transition: ${transition().custom};
  cursor: ${props => (props.hover ? "pointer" : "auto")};
  &:hover {
    opacity: ${props => (props.hover ? "0.7" : "1")};
  }
`;

const Label = styled.span`
  display: block;
  text-transform: uppercase;
  font-weight: 800;
  color: ${colors().black};
  padding-right: 10px;
`;

const BadgeLabel = styled.span`
  display: flex;
  align-items: center;
  margin-left: 93px;
  @media (${breakpoints().maxL}) {
    margin-left: 0;
  }
`;

const BadgeText = styled.div`
  width: 49px;
  height: 49px;
  background: ${props => (props.$background ? props.$background : "transparent")};
  border: 1px solid rgba(172, 129, 86, 0.15);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.cursor ? props.cursor : "auto")};
  flex-shrink: ${props => (props.flexShrink ? props.flexShrink : "1")};
  & + &{
    margin-left: 10px;
  }
  @media (${breakpoints().maxL}) {
    width: 45px;
    height: 45px;
  }
`;

const Badge = ({
  name,
  size = 32,
  hideBorder = false,
  dimnished = false,
  onClick,
  color,
  border,
  marginLeft,
  marginRight,
  marginBottom,
  hover,
  customBackground,
  className,
  noColor,
  iconSize
}) => {
  if (Badges.has(name)) {
    const {
      icon = "status_medal",
      background = "#fff",
      defaultBorder = "rgba(172, 129, 86, 0.15)",
      defaultColor = "#000",
      defaultMarginLeft = "10px",
      defaultMarginRight = "10px",
      defaultMarginBottom = "0",
      defaultHover = "",
      icoSize = 0.5
    } = Badges.get(name);
    return (
      <BadgeWrapper
        $background={customBackground ? customBackground : (!dimnished ? background : "transparent")}
        border={!dimnished ? (border ? border : defaultBorder) : "#b1afaf"}
        hideBorder={!dimnished ? hideBorder : false}
        marginLeft={marginLeft ? marginLeft : defaultMarginLeft}
        marginRight={marginRight ? marginRight : defaultMarginRight}
        marginBottom={marginBottom ? marginBottom : defaultMarginBottom}
        size={size}
        onClick={onClick}
        hover={hover ? hover : defaultHover}
        className={className ? className : ""}
      >
        <Icon
          icon={icon}
          color={!noColor ? (!dimnished ? (color ? color : defaultColor) : "#b1afaf") : ""}
          size={iconSize || size * icoSize}
        />
      </BadgeWrapper>
    );
  } else {
    return <div />;
  }
};

const BadgeWrap = styled.div`
  display: flex;
  margin-left: ${props => (props.marginLeft ? props.marginLeft + "px" : "auto")};
  margin-right: ${props => (props.marginRight ? props.marginRight + "px" : "0")};
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : "row")};
  align-items: ${props => (props.alignItems ? props.alignItems : "")};
`;

export {
  Badge,
  BadgeWrap,
  Label,
  BadgeLabel,
  BadgeText,
};
