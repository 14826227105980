import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { prop } from "ramda";

import { colors, transition } from "../../../UIGlobals";
import { styleWhenTrue } from "../../../helpers/rendering";

const ContainerStyles = `
  height: 42px;
  line-height: 42px;
  display: block;
  position: relative;
  width: 240px;
  margin-bottom: 10px;
  text-decoration: none;
  color: ${colors().deepGray};
  transition: ${transition().custom};

  &:hover {
    opacity: 0.6;
  }
`;

export const LinkContainer = styled(NavLink)`
  ${ContainerStyles}
`;

export const DisabledContainer = styled.div`
  ${ContainerStyles}
`;

export const NavIcon = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 4px;
  display: flex;
  background-color: ${colors().white};
  align-items: center;
  justify-content: center;
  float: left;
  margin-right: 20px;

  ${styleWhenTrue(prop("$active"), css`
    background-color: ${() => colors().brandBasic};
  `)}

  ${styleWhenTrue(prop("disabled"), css`
    background-color: ${() => colors().almostWhite};
  `)}
`;

export const NavLabel = styled.span`
  font-size: 13px;
  font-weight: 600;
  display: block;
  text-decoration: none;
`;
