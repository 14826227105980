import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router";

import { transition } from "../UIGlobals/";
import { clearApiCache } from "../helpers/clearApiCache";

import { UserContextActions } from "./redux/userContext.reducer";

const LogoutButton = styled.span`
  padding: ${props => (props.$padding ? props.$padding : "15px")};
  background-color: transparent;
  border: none;
  transition: ${transition().custom};
  cursor: pointer;
  z-index: 1;
  &:hover {
    opacity: 0.7;
  }
`;

const Logout = ({ children, logout, padding }) => {
  const navigate = useNavigate();

  const onLogOutClick = async () => {
    await logout();
    clearApiCache();
    navigate("/");
  }

  return (
    <LogoutButton $padding={padding} onClick={onLogOutClick}>{children}</LogoutButton>
  );
};

const mapStateToProps = ownProps => ({ ...ownProps });

const mapDispatchToProps = dispatch => ({ logout: () => dispatch(UserContextActions.setLoggedOut()) });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
